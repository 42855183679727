<template>
     <div class="page-content">
        <div  class="row">
             <img src="https://cdn.joeltankard.com/website/projects/phoenix/logo.png" />
            <div>
                <h2>How it started</h2>
                <p>When we began working on Always Here, our goal was clear: we wanted to create an AI companion for kids, something beyond basic text exchanges. The challenge was that existing tools in the market just didn't fit our vision.</p>
                <p>To tackle this, we crafted our own solution, a simple yet effective chat flow builder we called the 'Admin Tool.' It let us design complex conversations and importantly, allowed people like psychologists, who weren't tech-savvy, to create content without constantly needing help from our developers.</p>
            </div>
        </div>
        <div  class="row row-reverse">
                       <pre-video src="https://cdn.joeltankard.com/website/projects/phoenix/section-1.mp4" />
                 <div>
                <h2>The Challenge of Managing Two Projects</h2>
                <p>Juggling the development of Always Here and the Admin Tool turned out to be quite a task. The content team often felt frustrated by delays and bugs in the Admin Tool. From my past experiences, I knew that people tend to be more critical of internal tools than external ones. We also struggled with an ever-growing list of feature requests.</p>
                <p>Despite my objections, the company leaders decided to replace our tool with an external solution, Landbot, because it allowed for coding. However, this move came with its own set of problems. The team found limitations with Landbot, and our developers ended up being sidetracked to support the content team with technical issues.</p>
            </div>
        </div>

        <div class="row">
                           <pre-video src="https://cdn.joeltankard.com/website/projects/phoenix/section-2.mp4" />

            <div>
                <h2>Rise of Phoenix</h2>
                <p>Realizing the issues with Landbot, the CTO and I convinced the CEO and board to bring back our Admin Tool. The catch was we had to upgrade it to match Landbot's capabilities in just a week. It was a daunting task, but somehow, we managed to do it.</p>
                <p>Our revamped tool, which we fittingly named 'Phoenix,' was a significant improvement. We added new features like emotional layers to the companion and better integration with dialogue systems. It was a big win for us.</p>
                <p>As I took charge of Phoenix, it grew into a powerful tool that caught the interest of local startups. It seemed poised to become a standalone product with its own company, and I was ready to lead it. But then, priorities shifted back to Always Here due to its user growth issues.</p>
            </div>
        </div>

          <div  class="row row-reverse">
                           <pre-video src="https://cdn.joeltankard.com/website/projects/phoenix/section-3.mp4" />
                     <div>
                    <h2>Facing New Realities with ChatGPT</h2>
                    <p>The final challenge came with the arrival of ChatGPT. It was clear to me that the landscape was changing. We would be dependent on technology we couldn't control, which could easily change the game for everyone. Combined with the financial troubles of Always Here and the rapid advancements in AI, it meant that Phoenix, as we knew it, was no longer viable.</p>
                </div>
            </div>
        

        <div class="outcome">
                <h2>Outcome</h2>
                <div class="stats">
                    <div>
                        <h3>Life span</h3>
                        <h4>3 years</h4>
                    </div>
                    <div>
                        <h3>Staus</h3>
                        <h4>Cancelled</h4>
                    </div>
                </div>
                <div>
                    <h3>Summary</h3>
                    <p>Working on Phoenix was a deeply personal journey for me. It taught me valuable lessons in making the most of limited resources, in figuring out which features really mattered, and in managing a product from the ground up. Sure, it's a bit sad how things turned out with Phoenix, but it's also stirred something in me – a genuine excitement for the future of AI. We might have been a tad late to the game with our tool, but this experience has only made me more curious and enthusiastic about what I can explore next in this ever-evolving field.</p>
                </div>
        </div>
     </div>
</template>


<script>
import PreVideo from '@/components/PreVideo';

export default {
    name: 'Phoenix',
    components: {
        PreVideo
    }
}
</script>